@tailwind base;
@tailwind components;
@tailwind utilities;

body.lock-scroll {
  overflow: auto;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.react-select__menu {
  animation: dropdownFadeIn 0.5s ease-out;
  position: absolute !important;
  z-index: 1;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 450px;
  right: 180px;
  margin: 2px;
  width: 40px;
  height: 40px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Emails */
/* Přepíše výchozí styly knihovny */
.react-tagsinput .react-tagsinput-inputProps {
  width: 100% !important;
}
.react-tagsinput {
  background-color: #f8f9fa; /* Světlejší pozadí */
  border: 1px solid #ced4da; /* Šedý okraj */
  overflow: hidden;
  padding-left: 5px;
  width: 100% !important;
}

.react-tagsinput--focused {
  border-color: #80bdff; /* Světle modrý okraj při zaměření */
}

.react-tagsinput-tag {
  background-color: #060621 !important; /* Tmavě šedé pozadí */
  border-radius: 50px !important;
  border: 1px solid #312d4a !important; /* Tmavší šedý okraj */
  color: #fff !important; /* Bílý text */
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-left: 10px !important;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: #cc0000;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  border: 0;
  color: #343a40; /* Tmavě šedý text */
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 150px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  z-index: 10;
}

.modal {
  background: #2b313a;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
  margin-top: 20px;
}
/* Add this CSS in your main CSS file */
.line-numbers {
  width: 40px; /* Adjust width as necessary */
  padding: 10px;
  border-right: 1px solid #ddd;
  text-align: right;
  background-color: #f0f0f0;
  color: #888;
  overflow: hidden;
  user-select: none;
  resize: none; /* Prevent resizing */
}

/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #2b313a #242830; /* For Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2b313a; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

.draggable-item {
  position: relative; /* Nastavte relativní pozici pro kontejner */
}

.draggable-item .action-buttons {
  position: absolute; /* Absolutní pozice tlačítek uvnitř položky */
  top: 50%; /* Umístěte tlačítka do středu položky (můžete upravit podle potřeby) */
  right: 10px; /* Posuňte tlačítka od pravého okraje */
  z-index: 10; /* Zajistěte, že tlačítka budou nad přetahováním */
  display: flex;
  gap: 10px;
}

.draggable-item .action-buttons button {
  cursor: pointer; /* Zajistěte, že tlačítka budou mít správný kurzor */
}

@layer base {
  body {
    @apply font-primary;
  }
}

@layer utilities {
  .no-arrows {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .no-arrows::-webkit-inner-spin-button,
  .no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tři sloupce */
  gap: 16px; /* Mezera mezi obrázky */
  justify-content: center;
  margin-top: 100px;
  width: 640px; /* Nastavte šířku galerie */
}

.gallery-item {
  width: 100%; /* Šířka každého sloupce */
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  touch-action: none;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Tlačítko základ */
.scale-button {
  cursor: pointer;
  transition: transform 0.2s ease;
}

/* Efekt při kliknutí */
.scale-button:active {
  transform: scale(0.95);
}

.loader {
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
